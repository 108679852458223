// 从环境变量中获取API基础路径
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://58.49.150.163:18801/api';

console.log('API_BASE_URL:', API_BASE_URL); // 添加日志以便调试

// 普通请求（不需要认证）
export const fetchApi = async (url, options = {}) => {
    try {
        const fullUrl = `${API_BASE_URL}${url}`;
        console.log('Fetching URL:', fullUrl); // 添加日志以便调试
        
        const response = await fetch(fullUrl, {
            ...options,
            headers: {
                'Content-Type': 'application/json',
                ...(options.headers || {})
            }
        });
        return response;
    } catch (error) {
        console.error('API request error:', error);
        return null;
    }
};

export const getAuthHeaders = () => {
    const token = localStorage.getItem('token');
    const username = localStorage.getItem('username');
    return {
        'Authorization': token ? `Bearer ${token}` : '',
        'X-Username': username || '',
        'Content-Type': 'application/json'
    };
};

export const validateToken = async () => {
    try {
        const token = localStorage.getItem('token');
        if (!token) {
            return false;
        }

        const response = await fetch(`${API_BASE_URL}/users/validate-token`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            localStorage.removeItem('token');
            localStorage.removeItem('username');
            return false;
        }

        const data = await response.json();
        const isValid = data.valid === true;
        
        if (!isValid) {
            localStorage.removeItem('token');
            localStorage.removeItem('username');
        }
        
        return isValid;
    } catch (error) {
        console.error('Token validation error:', error);
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        return false;
    }
};

export const checkAuthAndRedirect = async () => {
    const isValid = await validateToken();
    if (!isValid) {
        window.location.href = '/login';
        return false;
    }
    return true;
};

// API请求工具函数
export const fetchWithAuth = async (url, options = {}) => {
    // 如果请求体是FormData，不设置Content-Type，让浏览器自动处理
    const isFormData = options.body instanceof FormData;
    
    const headers = {
        ...(!isFormData ? { 'Content-Type': 'application/json' } : {}),
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'X-Username': localStorage.getItem('username'),
        ...(options.headers || {})
    };

    try {
        const response = await fetch(`${API_BASE_URL}${url}`, {
            ...options,
            headers
        });

        if (response.status === 401) {
            localStorage.removeItem('token');
            localStorage.removeItem('username');
            window.location.href = '/login';
            return null;
        }

        return response;
    } catch (error) {
        console.error('API request error:', error);
        return null;
    }
}; 