import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import { DatePicker, Space, Pagination } from 'antd';
import moment from 'moment';
import './ExcelAnalyzer.css';
import { fetchWithAuth } from '../utils/api';

const { RangePicker } = DatePicker;

const ExcelAnalyzer = ({ userRole }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchName, setSearchName] = useState('');
  const [dateRange, setDateRange] = useState([]);
  const [hasSearched, setHasSearched] = useState(false);
  const [searchError, setSearchError] = useState('');
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0
  });

  const isAdmin = () => {
    const userDataStr = localStorage.getItem('userData');
    if (userDataStr) {
      const userData = JSON.parse(userDataStr);
      return userData.role === 'admin';
    }
    return false;
  };

  const validateSearch = () => {
    if (!isAdmin()) {
      if (!searchName && (!dateRange || dateRange.length !== 2)) {
        setSearchError('请输入姓名或选择职位发布日期范围');
        return false;
      }
    }
    setSearchError('');
    return true;
  };

  const handleSearch = async (page = 1, pageSize = 10) => {
    if (!validateSearch()) {
      return;
    }

    try {
      setLoading(true);
      setHasSearched(true);
      // 确保page是有效的数字
      const currentPage = parseInt(page) || 1;
      const currentSize = parseInt(pageSize) || 10;
      
      let url = `/excel/search?page=${currentPage - 1}&size=${currentSize}`;
      
      if (searchName) {
        url += `&name=${encodeURIComponent(searchName)}`;
      }
      if (dateRange && dateRange.length === 2) {
        url += `&startDate=${encodeURIComponent(dateRange[0].format('YYYY-MM-DD'))}`;
        url += `&endDate=${encodeURIComponent(dateRange[1].format('YYYY-MM-DD'))}`;
      }

      const response = await fetchWithAuth(url);
      if (!response || !response.ok) {
        throw new Error('搜索失败');
      }
      const result = await response.json();
      setData(result.content || []);
      setPagination({
        current: currentPage,
        pageSize: currentSize,
        total: result.totalElements || 0
      });
    } catch (error) {
      console.error('Error searching:', error);
      alert('搜索失败，请重试');
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page, pageSize) => {
    // 确保传入的是数字
    const newPage = parseInt(page) || 1;
    const newSize = parseInt(pageSize) || 10;
    handleSearch(newPage, newSize);
  };

  // 搜索条件改变时重置分页并搜索
  const handleSearchConditionChange = () => {
    if (validateSearch()) {
      handleSearch(1, pagination.pageSize);
    }
  };

  useEffect(() => {
    if (userRole?.canViewData && isAdmin()) {
      handleSearch(1, 10);
    }
  }, [userRole]);

  // 监听搜索条件变化
  useEffect(() => {
    if (hasSearched) {
      handleSearchConditionChange();
    }
  }, [searchName, dateRange]);

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    try {
      setLoading(true);
      const excelData = await readExcelFile(file);

      const formData = new FormData();
      formData.append('file', file);
      formData.append('content', JSON.stringify(excelData));

      const response = await fetchWithAuth('/excel/upload', {
        method: 'POST',
        body: formData
      });

      if (!response || !response.ok) {
        throw new Error('上传失败');
      }

      alert('上传成功');
      handleSearch(1, pagination.pageSize);
    } catch (error) {
      console.error('Upload error:', error);
      alert('上传失败，请重试');
    } finally {
      setLoading(false);
      e.target.value = null; // 重置文件输入
    }
  };

  const readExcelFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: 'array' });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: false });
          resolve(jsonData);
        } catch (error) {
          reject(error);
        }
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  };

  return (
    <div className="excel-analyzer">
      <h2>Excel 数据分析器</h2>
      
      {userRole?.canUploadFile && (
        <div className="upload-section">
          <div className="file-input-wrapper">
            <label className="file-input-button">
              选择 Excel 文件
              <input
                type="file"
                accept=".xlsx, .xls"
                onChange={handleFileUpload}
              />
            </label>
          </div>
        </div>
      )}

      <div className="search-section">
        <Space size={16} direction="vertical">
          <Space size={16} wrap align="center">
            <input
              type="text"
              placeholder="搜索姓名"
              value={searchName}
              onChange={(e) => setSearchName(e.target.value)}
              className="search-input"
            />
            <RangePicker
              value={dateRange}
              onChange={(dates) => setDateRange(dates)}
              placeholder={['职位发布开始日期', '职位发布结束日期']}
              format="YYYY-MM-DD"
              allowClear={true}
              separator="至"
              suffixIcon={<span style={{ color: '#666' }}>📅</span>}
              popupStyle={{ 
                padding: '12px',
                borderRadius: '8px',
                boxShadow: '0 6px 16px rgba(0,0,0,0.08)'
              }}
              superNextIcon={<span style={{ fontSize: '16px' }}>»</span>}
              nextIcon={<span style={{ fontSize: '16px' }}>›</span>}
              prevIcon={<span style={{ fontSize: '16px' }}>‹</span>}
              superPrevIcon={<span style={{ fontSize: '16px' }}>«</span>}
            />
            <button onClick={() => handleSearch(1, pagination.pageSize)} className="search-button">
              搜索
            </button>
          </Space>
          {!isAdmin() && (
            <div className="search-tip">
              请输入姓名或选择职位发布日期范围进行搜索
            </div>
          )}
          {searchError && (
            <div className="error-message">
              {searchError}
            </div>
          )}
        </Space>
      </div>

      {loading && (
        <div className="loading">
          <div className="loading-spinner"></div>
          <p>数据加载中...</p>
        </div>
      )}

      {data.length > 0 ? (
        <div className="data-list">
          <div className="table-responsive">
            <table>
              <thead>
                <tr>
                  <th>BossId</th>
                  <th>姓名</th>
                  <th>Boss职务</th>
                  <th>Boss注册时间</th>
                  <th>Boss上次活跃时间</th>
                  <th>公司简称</th>
                  <th>公司全称</th>
                  <th>职位ID</th>
                  <th>职位名称</th>
                  <th>三级类</th>
                  <th>二级类</th>
                  <th>一级类</th>
                  <th>城市</th>
                  <th>职位发布时间</th>
                  <th>职位在线状态</th>
                  <th>Boss查看牛人数</th>
                  <th>职位查看数</th>
                  <th>Boss开聊数</th>
                  <th>牛人开聊数</th>
                  <th>Boss开聊后回聊数</th>
                  <th>牛人开聊后回聊数</th>
                  <th>双聊数</th>
                  <th>获取简历数</th>
                  <th>是否为老师（非美术）岗位</th>
                  <th>是否为CC</th>
                  <th>是否为班主任</th>
                  <th>是否为销售类岗位</th>
                  <th>开聊数合计</th>
                  <th>结果数合计</th>
                  <th>数据时间</th>
                </tr>
              </thead>
              <tbody>
                {data.map((row, index) => (
                  <tr key={index}>
                    <td>{row.bossId}</td>
                    <td>{row.name}</td>
                    <td>{row.bossPosition}</td>
                    <td>{row.bossRegisterTime}</td>
                    <td>{row.bossLastActiveTime}</td>
                    <td>{row.companyShortName}</td>
                    <td>{row.companyFullName}</td>
                    <td>{row.jobId}</td>
                    <td>{row.jobName}</td>
                    <td>{row.thirdCategory}</td>
                    <td>{row.secondCategory}</td>
                    <td>{row.firstCategory}</td>
                    <td>{row.city}</td>
                    <td>{row.jobPublishTime}</td>
                    <td>{row.jobOnlineStatus}</td>
                    <td>{row.bossViewCount}</td>
                    <td>{row.jobViewCount}</td>
                    <td>{row.bossInitiateChat}</td>
                    <td>{row.talentInitiateChat}</td>
                    <td>{row.bossReplyChat}</td>
                    <td>{row.talentReplyChat}</td>
                    <td>{row.mutualChat}</td>
                    <td>{row.resumeCount}</td>
                    <td>{row.isTeacherPosition}</td>
                    <td>{row.isCC}</td>
                    <td>{row.isHeadTeacher}</td>
                    <td>{row.isSalesPosition}</td>
                    <td>{row.totalChatCount}</td>
                    <td>{row.totalResultCount}</td>
                    <td>{row.dataTime}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="pagination-container">
            <Pagination
              current={pagination.current}
              pageSize={pagination.pageSize}
              total={pagination.total}
              onChange={handlePageChange}
              showSizeChanger
              showQuickJumper
              showTotal={(total) => `共 ${total} 条数据`}
            />
          </div>
        </div>
      ) : !loading && hasSearched && (
        <div className="no-data">
          <p>暂无数据</p>
        </div>
      )}
    </div>
  );
};

export default ExcelAnalyzer; 