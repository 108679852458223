import React, { useState, useEffect, useCallback, memo } from 'react';
import './UserManagement.css';

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const defaultFormData = {
    id: null,
    username: '',
    password: '',
    realName: '',
    email: '',
    role: 'user',
    status: true,
    permissions: {
      canLogin: true,
      canViewData: true,
      canUploadFile: false,
      canDownloadData: false,
      canEditData: false,
      canDeleteData: false,
      canManageUsers: false
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'X-Username': localStorage.getItem('username')
        }
      });
      if (!response.ok) {
        throw new Error('获取用户列表失败');
      }
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      console.error('Error fetching users:', error);
      alert('获取用户列表失败');
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (user) => {
    setCurrentUser({
      ...user,
      password: '',
      permissions: user.permission
    });
    setShowEditModal(true);
  };

  const handleBatchDelete = async () => {
    if (selectedUsers.length === 0) {
      alert('请选择要删除的用户');
      return;
    }

    if (!window.confirm(`确定要删除选中的 ${selectedUsers.length} 个用户吗？`)) {
      return;
    }

    try {
      const deletePromises = selectedUsers.map(userId =>
        fetch(`${process.env.REACT_APP_API_BASE_URL}/users/${userId}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'X-Username': localStorage.getItem('username')
          }
        })
      );

      await Promise.all(deletePromises);
      await fetchUsers();
      setSelectedUsers([]);
    } catch (error) {
      console.error('Error:', error);
      alert('删除用户失败');
    }
  };

  const handleSubmit = async (formData) => {
    try {
      const url = formData.id 
        ? `${process.env.REACT_APP_API_BASE_URL}/users/${formData.id}`
        : `${process.env.REACT_APP_API_BASE_URL}/users`;
      
      const response = await fetch(url, {
        method: formData.id ? 'PUT' : 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
          'X-Username': localStorage.getItem('username')
        },
        body: JSON.stringify(formData)
      });

      if (!response.ok) {
        throw new Error(formData.id ? '更新用户失败' : '创建用户失败');
      }

      await fetchUsers();
      setShowAddModal(false);
      setShowEditModal(false);
      setCurrentUser(null);
    } catch (error) {
      console.error('Error:', error);
      alert(error.message);
    }
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedUsers(users.map(user => user.id));
    } else {
      setSelectedUsers([]);
    }
  };

  const handleSelectUser = (userId) => {
    setSelectedUsers(prev => {
      if (prev.includes(userId)) {
        return prev.filter(id => id !== userId);
      } else {
        return [...prev, userId];
      }
    });
  };

  const UserForm = memo(({ onClose, initialData, onSubmit }) => {
    const [formData, setFormData] = useState(initialData);

    const handleInputChange = useCallback((e) => {
      const { name, value } = e.target;
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }, []);

    const handlePermissionChange = useCallback((permission) => {
      setFormData(prev => ({
        ...prev,
        permissions: {
          ...prev.permissions,
          [permission]: !prev.permissions[permission]
        }
      }));
    }, []);

    const handleSubmit = useCallback(() => {
      onSubmit(formData);
    }, [formData, onSubmit]);

    return (
      <div className="user-form">
        <div className="form-basic-info">
          <div className="form-group">
            <label>用户名:</label>
            <input
              className="basic-input"
              type="text"
              name="username"
              value={formData.username}
              onChange={handleInputChange}
              disabled={!!initialData.id}
              required
            />
          </div>
          <div className="form-group">
            <label>密码:</label>
            <input
              className="basic-input"
              type="password"
              name="password"
              value={formData.password}
              onChange={handleInputChange}
              required={!initialData.id}
              placeholder={initialData.id ? "留空表示不修改" : ""}
            />
          </div>
          <div className="form-group">
            <label>姓名:</label>
            <input
              className="basic-input"
              type="text"
              name="realName"
              value={formData.realName}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>邮箱:</label>
            <input
              className="basic-input"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>角色:</label>
            <select
              className="basic-input"
              name="role"
              value={formData.role}
              onChange={handleInputChange}
            >
              <option value="user">普通用户</option>
              <option value="admin">管理员</option>
            </select>
          </div>
          <div className="form-group">
            <label>状态:</label>
            <select
              className="basic-input"
              name="status"
              value={formData.status}
              onChange={handleInputChange}
            >
              <option value="true">启用</option>
              <option value="false">禁用</option>
            </select>
          </div>
        </div>

        <div className="permissions">
          <div className="form-group">
            <label>权限设置:</label>
            <div className="permission-options">
              <label>
                <input
                  type="checkbox"
                  checked={formData.permissions.canLogin}
                  onChange={() => handlePermissionChange('canLogin')}
                />
                登录系统
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={formData.permissions.canViewData}
                  onChange={() => handlePermissionChange('canViewData')}
                />
                查看数据
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={formData.permissions.canUploadFile}
                  onChange={() => handlePermissionChange('canUploadFile')}
                />
                上传文件
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={formData.permissions.canDownloadData}
                  onChange={() => handlePermissionChange('canDownloadData')}
                />
                下载数据
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={formData.permissions.canEditData}
                  onChange={() => handlePermissionChange('canEditData')}
                />
                修改数据
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={formData.permissions.canDeleteData}
                  onChange={() => handlePermissionChange('canDeleteData')}
                />
                删除数据
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={formData.permissions.canManageUsers}
                  onChange={() => handlePermissionChange('canManageUsers')}
                />
                管理用户
              </label>
            </div>
          </div>
        </div>

        <div className="form-actions">
          <button type="button" className="cancel-button" onClick={onClose}>
            取消
          </button>
          <button type="button" className="submit-button" onClick={handleSubmit}>
            {initialData.id ? '更新' : '创建'}
          </button>
        </div>
      </div>
    );
  });

  const Modal = memo(({ show, onClose, title, children }) => {
    const handleOverlayClick = useCallback((e) => {
      if (e.target.className === 'modal-overlay') {
        onClose();
      }
    }, [onClose]);

    if (!show) return null;

    return (
      <div className="modal-overlay" onClick={handleOverlayClick}>
        <div className="modal" onClick={e => e.stopPropagation()}>
          <div className="modal-header">
            <h3>{title}</h3>
          </div>
          <div className="modal-content">
            {children}
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="user-management">
      <div className="header">
        <h2>用户管理</h2>
        <div className="header-actions">
          <button onClick={() => setShowAddModal(true)} className="add-button">
            <i className="fas fa-plus"></i>
            添加用户
          </button>
          <button 
            onClick={handleBatchDelete} 
            className="delete-button"
            disabled={selectedUsers.length === 0}
          >
            <i className="fas fa-trash"></i>
            删除{selectedUsers.length > 0 ? `(${selectedUsers.length})` : ''}
          </button>
        </div>
      </div>

      {loading ? (
        <div className="loading">
          <i className="fas fa-spinner fa-spin"></i>
          <span>加载中...</span>
        </div>
      ) : (
        <div className="users-table">
          <table>
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    checked={selectedUsers.length === users.length}
                    onChange={handleSelectAll}
                  />
                </th>
                <th>用户名</th>
                <th>姓名</th>
                <th>邮箱</th>
                <th>角色</th>
                <th>状态</th>
                <th>权限</th>
                <th>创建时间</th>
                <th>最后登录</th>
                <th>操作</th>
              </tr>
            </thead>
            <tbody>
              {users.map(user => (
                <tr key={user.id}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedUsers.includes(user.id)}
                      onChange={() => handleSelectUser(user.id)}
                    />
                  </td>
                  <td>{user.username}</td>
                  <td>{user.realName || '-'}</td>
                  <td>{user.email || '-'}</td>
                  <td>{user.role === 'admin' ? '管理员' : '普通用户'}</td>
                  <td>
                    <span className={`status ${user.status ? 'active' : 'inactive'}`}>
                      <i className={`fas fa-${user.status ? 'check' : 'times'}`}></i>
                      {user.status ? '启用' : '禁用'}
                    </span>
                  </td>
                  <td>
                    <div className="permission-tags">
                      {user.permission?.canUploadFile && <span className="tag"><i className="fas fa-upload"></i> 上传</span>}
                      {user.permission?.canDownloadData && <span className="tag"><i className="fas fa-download"></i> 下载</span>}
                      {user.permission?.canEditData && <span className="tag"><i className="fas fa-edit"></i> 编辑</span>}
                      {user.permission?.canDeleteData && <span className="tag"><i className="fas fa-trash"></i> 删除</span>}
                      {user.permission?.canManageUsers && <span className="tag"><i className="fas fa-users-cog"></i> 管理</span>}
                    </div>
                  </td>
                  <td>{new Date(user.createdAt).toLocaleString()}</td>
                  <td>{user.lastLoginTime ? new Date(user.lastLoginTime).toLocaleString() : '-'}</td>
                  <td>
                    <button onClick={() => handleEdit(user)} className="edit-button">
                      <i className="fas fa-edit"></i>
                      编辑
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <Modal
        show={showAddModal}
        onClose={() => {
          setShowAddModal(false);
          setCurrentUser(null);
        }}
        title="添加用户"
      >
        <UserForm 
          onClose={() => {
            setShowAddModal(false);
            setCurrentUser(null);
          }}
          initialData={defaultFormData}
          onSubmit={handleSubmit}
        />
      </Modal>

      <Modal
        show={showEditModal}
        onClose={() => {
          setShowEditModal(false);
          setCurrentUser(null);
        }}
        title="编辑用户"
      >
        <UserForm 
          onClose={() => {
            setShowEditModal(false);
            setCurrentUser(null);
          }}
          initialData={currentUser || defaultFormData}
          onSubmit={handleSubmit}
        />
      </Modal>
    </div>
  );
};

export default UserManagement; 