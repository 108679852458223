import React, { useState } from 'react';
import ExcelAnalyzer from './components/ExcelAnalyzer';
import UserManagement from './components/UserManagement';
import Login from './components/Login';
import './App.css';

function App() {
  const [user, setUser] = useState(null);
  const [currentView, setCurrentView] = useState('data'); // 'data' or 'users'

  const handleLogin = (userData) => {
    setUser(userData);
  };

  const handleLogout = () => {
    setUser(null);
    setCurrentView('data');
  };

  const switchView = (view) => {
    setCurrentView(view);
  };

  return (
    <div className="App">
      {!user ? (
        <Login onLogin={handleLogin} />
      ) : (
        <div>
          <div className="header">
            <div className="nav-links">
              <button 
                className={`nav-button ${currentView === 'data' ? 'active' : ''}`}
                onClick={() => switchView('data')}
              >
                数据管理
              </button>
              {user.permission?.canManageUsers && (
                <button 
                  className={`nav-button ${currentView === 'users' ? 'active' : ''}`}
                  onClick={() => switchView('users')}
                >
                  用户管理
                </button>
              )}
            </div>
            <div className="user-info">
              <span>欢迎, {user.username}</span>
              <button className="logout-button" onClick={handleLogout}>
                退出登录
              </button>
            </div>
          </div>
          <div className="content">
            {currentView === 'data' ? (
              <ExcelAnalyzer userRole={user.permission} />
            ) : (
              <UserManagement />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
